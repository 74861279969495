.Home{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.background {
    background-color: white; /* 연한 회색 배경 */
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px; /* 양쪽에 패딩 추가 */
}
.title-box{
    width: 100%;
    position: absolute;
    align-items: center;


}
.title{
    display: flex;
    font-family: YiSunShinRegular;
    justify-content: center;
    font-size: 40px;
}



.top-bar {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 70px;
    background-color: white;
    border: 1px solid #f1f3f6;
    border-top: none;
}

.logo {
    margin-left: 20px;
    width: 50px;
    height: 50px;
}

.BETA {
    margin-left: 10px;
    width: 66px;
    height: 20px;
}

.top-bar-text {
    margin-left: 10px;
    font-size: 30px;
    font-family: 'Pretendard-Regular';
    color: #333; /* 어두운 색상으로 텍스트 강조 */
}

.center-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* 상단에 정렬 */
    align-items: center;
    margin-left: 300px;
    margin-right: 300px;
    width: 1300px;
    height: auto;
    background-color: white;
    border: 1px solid #f1f3f6;
    border-top: 0; /* 윗테두리 제거 */

}
.lottie-animation {
    width: 100px; /* 너비를 100%로 설정 */
    height: 100px; /* 비율에 맞게 높이 조정 */
}

.HOT-TEXT {
    margin-top: 10px;
    font-size: 50px;
    color: red;
}

/* 박스를 호버했을 때 효과 */
.hot-content-box:hover {
    transform: translateY(-10px); /* 박스가 살짝 올라감 */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 호버 시 그림자 더 진하게 */
}

.button-box {
    display: flex; /* Flexbox 사용 */
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 (필요에 따라) */
    gap: 50px; /* 버튼 간의 간격 설정 */
    margin-top: 20px; /* 버튼 박스 상단 여백 */
}

.forgive-button {
    width: 300px;
    height: 70px;
    background-color: deepskyblue;
    border-radius: 15px;
    font-family: 'Pretendard-Regular';
    font-size: 25px;
    color: white; /* 텍스트 색상 흰색 */
    border: none; /* 테두리 제거 */
    transition: background-color 0.3s ease; /* 부드러운 배경색 변화 */
}

/* 버튼 호버 효과 */
.forgive-button:hover {
    background-color: #0088cc; /* 다크 블루로 변경 */
}

.not-forgive-button {
    width: 300px;
    height: 70px;
    background-color: orangered;
    border-radius: 15px;
    font-family: 'Pretendard-Regular';
    font-size: 25px;
    color: white; /* 텍스트 색상 흰색 */
    border: none; /* 테두리 제거 */
    transition: background-color 0.3s ease; /* 부드러운 배경색 변화 */
}

/* 버튼 호버 효과 */
.not-forgive-button:hover {
    background-color: #c0392b; /* 다크 레드로 변경 */
}

.notice-top-bar {
    margin-top: 50px;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center; /* 버튼들을 중앙에 정렬 */
    align-items: center; /* 버튼을 세로로 가운데 정렬 */
    gap: 50px; /* 버튼 간 간격을 20px로 조정 */
}

.Popular-button,
.Real-time-button,
.Forgive-sort-button,
.NotForgive-sort-button {
    width: 130px;
    height: 50px; /* 비율을 %로 주지 않고 고정값으로 설정 */
    background-color: whitesmoke;
    border-radius: 50px;
    border: 1px solid grey;
    color: black;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.1s ease;
}

.Popular-button:hover,
.Real-time-button:hover,
.Forgive-sort-button:hover,
.NotForgive-sort-button:hover {
    background-color: grey;
}

.notice-board-box {
    gap: 20px;
    display: flex; /* Flexbox 사용 */
    flex-direction: column; /* 세로 방향으로 정렬 */
    justify-content: flex-start; /* 수직 중앙 정렬 */
    align-items: center; /* 수평 중앙 정렬 */
    margin-top: 50px; /* 상단 여백 */
    width: 1000px; /* 너비 100% */
    min-height: 100vh; /* 최소 높이 설정 */
    height: auto; /* 높이 자동 설정 */
    background-color: white; /* 배경색 */
}

.create-crime {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%; /* 전체 너비 */
    height: 100%; /* 높이를 400px로 증가 */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto; /* 수평 중앙 정렬 */
    border-radius: 8px;
    background-color: #f7f7f7; /* 배경색 */
}



.line {
    background-color: darkgray;
    width: 65%;
    height: 1px;
}

.gohaesungsa {
    position: fixed; /* 고정 위치로 변경 */
    right: 50px;
    top: 10px; /* 바닥에서 20px 떨어진 위치로 설정 (원하는 위치로 조정 가능) */
    background-color: #f9f3e6; /* 부드러운 크림색 */
    color: #8B5A2B; /* 어두운 갈색 글씨 */
    border: 2px solid #d4af37; /* 금색 테두리 */
    border-radius: 8px; /* 둥근 모서리 */
    padding: 12px 20px; /* 여백 */
    font-family: 'Georgia', serif; /* 우아한 서체 */
    font-size: 16px; /* 글씨 크기 */
    cursor: pointer; /* 포인터 커서 */
    transition: all 0.3s ease; /* 부드러운 전환 효과 */
    z-index: 9999; /* 최상단으로 올리는 z-index 값 */

}

.gohaesungsa:hover {
    background-color: #e2cda1; /* 호버 시 약간 어두워지는 색상 */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* 약간의 그림자 효과 */
}
.textarea-box{
    height: auto;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.pwbutton {
    background-color: #28a745; /* 초록색 배경 (확인 느낌) */
    color: white;              /* 흰색 글씨 */
    width: 100px;              /* 고정된 너비 */
    height: 40px;              /* 고정된 높이 */
    margin-top: 10px;          /* 위쪽 여백 */
    margin-bottom: 20px;       /* 아래쪽 여백 */
    margin-left: 10px;         /* 왼쪽 여백 */
    border: none;              /* 테두리 없음 */
    border-radius: 5px;       /* 둥근 모서리 */
    font-size: 16px;          /* 글씨 크기 */
    cursor: pointer;          /* 포인터 커서 */
    transition: background-color 0.3s, transform 0.2s; /* 효과 전환 */
}

.pwbutton:hover {
    background-color: #218838; /* 호버 시 색상 변경 */
    transform: scale(1.05);    /* 호버 시 약간 확대 */
}

.pwbutton:focus {
    outline: none;             /* 포커스 시 기본 테두리 없애기 */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 파란색 그림자 효과 */
}
.warning-text{
    font-size: 15px;
}
.text1{
    font-size: 20px;
}


.serch {
    margin-top: 30px;
    width: 600px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    justify-content: center;
}

.search-input {
    width: 500px;
    padding: 10px 15px;
    border: 2px solid #A9A9A9; /* 은은한 회색 테두리 */
    border-radius: 20px 0 0 20px; /* 왼쪽 둥근 모서리 */
    outline: none;
    font-size: 16px;
    color: #444444; /* 다소 진한 글자색 */
    background-color: #FAFAFA; /* 밝은 배경색 */
    transition: box-shadow 0.3s ease, border 0.3s ease;
}

.search-input:focus {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 포커스 시 그림자 효과 */
    border-color: #5B9BD5; /* 파란색 테두리 */
}

.search-button {
    padding: 10px 15px;
    background-color: #EAEAEA; /* 버튼 배경색을 밝은 회색으로 */
    color: #5B5B5B; /* 진한 회색 텍스트 */
    border: 2px solid #A9A9A9;
    border-left: none; /* 왼쪽 테두리 제거 */
    border-radius: 0 20px 20px 0; /* 오른쪽 둥근 모서리 */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.search-button:hover {
    background-color: #4A90E2; /* 호버 시 밝은 파란색 */
    color: white; /* 호버 시 흰색 텍스트 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 호버 시 그림자 */
}
.create-crime-button-text{
    color: red;

}



.ip-count{
    position: absolute;
    left: 20px;
    top: 100px;
    width: 200px;
    height: 400px;
}

.nickname,
.password {
    width: 200px;
    height: 15px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 18px;
    transition: border 0.3s, box-shadow 0.3s;
}

.crime-textarea {
    width: 90%; /* 너비 설정 */
    height: 90%;
    padding: 15px; /* 패딩 증가 */
    margin-bottom: 20px; /* 여백 증가 */
    border: 2px solid #ccc; /* 테두리 색상 */
    border-radius: 8px; /* 테두리 둥글게 */
    font-size: 18px; /* 글꼴 크기 */
    transition: border 0.3s, box-shadow 0.3s; /* 애니메이션 추가 */
    resize: vertical; /* 수직 방향으로만 크기 조정 가능 */
    min-height: 100px; /* 최소 높이 설정 */
    white-space: pre-wrap;
}

.crime-textarea:focus {
    border-color: #4A90E2; /* 포커스 시 테두리 색상 변경 */
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5); /* 포커스 시 그림자 추가 */
    outline: none; /* 기본 아웃라인 제거 */
}
.create-crime-button {
    margin-top: 10px;
    background-color: saddlebrown; /* 회색 배경 */
    color: white;              /* 흰색 글씨 */
    padding: 12px 24px;       /* 안쪽 여백 */
    border: none;              /* 테두리 없음 */
    border-radius: 5px;       /* 둥근 모서리 */
    font-size: 16px;          /* 글씨 크기 */
    cursor: pointer;          /* 포인터 커서 */
    text-transform: uppercase; /* 텍스트를 대문자로 변환 */
    letter-spacing: 1px;      /* 글자 간격 */
    transition: background-color 0.3s, transform 0.2s; /* 효과 전환 */
}

.create-crime-button:hover {
    background-color: #5a6268; /* 호버 시 색상 변경 */
    transform: scale(1.05);    /* 호버 시 약간 확대 */
}

.create-crime-button:focus {
    outline: none;             /* 포커스 시 기본 테두리 없애기 */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 파란색 그림자 효과 */
}
@media (max-width: 1000px) {
    .top-bar {
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 99%;
        height: 70px;
        border: 1px solid #f1f3f6;
        border-top: none;
    }
    .title-box{
        width: 99%;
        position: absolute;
        align-items: center;


    }
    .title{
        margin-top: 150px;
        display: flex;
        font-family: YiSunShinRegular;
        justify-content: center;
        font-size: 30px;
    }
    .center-box {
         display: flex;
         flex-direction: column;
         justify-content: center; /* 상단에 정렬 */
         align-items: center;

         width: 99%;
         height: auto;
         background-color: white;
         border: 1px solid #f1f3f6;
         border-top: 0; /* 윗테두리 제거 */

     }


    .lottie-animation {
        margin-top: 100px;
        width: 100px; /* 너비를 100%로 설정 */
        height: 100px; /* 비율에 맞게 높이 조정 */
    }

    .serch {
        margin-top: 30px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search-input {
        width: 60%;
        padding: 10px 15px;
        border: 2px solid #A9A9A9; /* 은은한 회색 테두리 */
        border-radius: 20px 0 0 20px; /* 왼쪽 둥근 모서리 */
        outline: none;
        font-size: 16px;
        color: #444444; /* 다소 진한 글자색 */
        background-color: #FAFAFA; /* 밝은 배경색 */
        transition: box-shadow 0.3s ease, border 0.3s ease;
    }
    .notice-top-bar {
        margin-top: 50px;
        width: 99%;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;  /* 요소들 사이에 균등한 간격을 설정 */
        align-items: center; /* 버튼을 세로로 가운데 정렬 */
        gap: 5px; /* 버튼 간 간격을 20px로 조정 */
    }

    .Popular-button,
    .Real-time-button,
    .Forgive-sort-button,
    .NotForgive-sort-button {
        width: 120px;
        height: 40px; /* 비율을 %로 주지 않고 고정값으로 설정 */
        background-color: whitesmoke;
        border-radius: 50px;
        border: 1px solid grey;
        color: black;
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.1s ease;
    }
    .gohaesungsa {
        position: absolute;
        right: 10px;
        top: 10px; /* 바닥에서 20px 떨어진 위치로 설정 (원하는 위치로 조정 가능) */
        background-color: #f9f3e6; /* 부드러운 크림색 */
        color: #8B5A2B; /* 어두운 갈색 글씨 */
        border: 2px solid #d4af37; /* 금색 테두리 */
        border-radius: 8px; /* 둥근 모서리 */
        padding: 12px 20px; /* 여백 */
        font-family: 'Georgia', serif; /* 우아한 서체 */
        font-size: 16px; /* 글씨 크기 */
        cursor: pointer; /* 포인터 커서 */
        transition: all 0.3s ease; /* 부드러운 전환 효과 */
        z-index: 9999; /* 최상단으로 올리는 z-index 값 */

    }
    .ip-count{
        position: absolute;
        left: 20px;
        top: 150px;
        width: 200px;
        height: 400px;
    }
    .background {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;


    }
    .line {
        background-color: darkgray;
        width: 90%;
        height: 1px;
    }
    .textarea-box{
        height: auto;
        gap: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }


    .logo {
        margin-left: 5px;
        width: 30px;
        height: 30px;
    }

    .BETA {
        margin-left: 5px;
        width: 50px;
        height: 15px;
    }

    .top-bar-text {
        margin-left: 5px;
        font-size: 20px;
        font-family: 'Pretendard-Regular';
        color: #333; /* 어두운 색상으로 텍스트 강조 */
    }
    .notice-board-box {
        gap: 20px;
        display: flex; /* Flexbox 사용 */
        flex-direction: column; /* 세로 방향으로 정렬 */
        justify-content: flex-start; /* 수직 중앙 정렬 */
        align-items: center; /* 수평 중앙 정렬 */
        margin-top: 50px; /* 상단 여백 */
        width: 100%; /* 너비 100% */
        min-height: 100vh; /* 최소 높이 설정 */
        height: auto; /* 높이 자동 설정 */
        background-color: white; /* 배경색 */
    }
    .create-crime {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%; /* 전체 너비 */
        height: 100%; /* 높이를 400px로 증가 */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto; /* 수평 중앙 정렬 */
        border-radius: 8px;
        background-color: #f7f7f7; /* 배경색 */
    }
    .warning-text{
        font-size: 10px;
    }
    .text1{
        font-size: 20px;
    }

    .nickname,
    .password {
        width: 100%;
        height: 10px;
        border: 2px solid #ccc;
        border-radius: 8px;
        font-size: 18px;
        transition: border 0.3s, box-shadow 0.3s;
    }

    .crime-textarea {
        width: 80%; /* 너비 설정 */
        height: 20%;
        border: 2px solid #ccc; /* 테두리 색상 */
        border-radius: 8px; /* 테두리 둥글게 */
        font-size: 18px; /* 글꼴 크기 */
        transition: border 0.3s, box-shadow 0.3s; /* 애니메이션 추가 */
        resize: vertical; /* 수직 방향으로만 크기 조정 가능 */
        white-space: pre-wrap;
    }

    .crime-textarea:focus {
        border-color: #4A90E2; /* 포커스 시 테두리 색상 변경 */
        box-shadow: 0 0 5px rgba(74, 144, 226, 0.5); /* 포커스 시 그림자 추가 */
        outline: none; /* 기본 아웃라인 제거 */
    }

    .create-crime-button {
        background-color: saddlebrown; /* 회색 배경 */
        color: white;              /* 흰색 글씨 */
        border: none;              /* 테두리 없음 */
        border-radius: 5px;       /* 둥근 모서리 */
        font-size: 16px;          /* 글씨 크기 */
        cursor: pointer;          /* 포인터 커서 */
        text-transform: uppercase; /* 텍스트를 대문자로 변환 */
        letter-spacing: 1px;      /* 글자 간격 */
        transition: background-color 0.3s, transform 0.2s; /* 효과 전환 */
    }

    .create-crime-button:hover {
        background-color: #5a6268; /* 호버 시 색상 변경 */
        transform: scale(1.05);    /* 호버 시 약간 확대 */
    }

    .create-crime-button:focus {
        outline: none;             /* 포커스 시 기본 테두리 없애기 */
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 파란색 그림자 효과 */
    }
    .create-crime-button-text{
        color: red;
        font-size: 10px;

    }
    .pwbutton {
        background-color: #28a745; /* 초록색 배경 (확인 느낌) */
        color: white;              /* 흰색 글씨 */
        width: 70px;              /* 고정된 너비 */
        height: 30px;              /* 고정된 높이 */
        margin-left: 10px;         /* 왼쪽 여백 */
        border: none;              /* 테두리 없음 */
        border-radius: 5px;       /* 둥근 모서리 */
        font-size: 13px;          /* 글씨 크기 */
        cursor: pointer;          /* 포인터 커서 */
        transition: background-color 0.3s, transform 0.2s; /* 효과 전환 */
    }

    .pwbutton:hover {
        background-color: #218838; /* 호버 시 색상 변경 */
        transform: scale(1.05);    /* 호버 시 약간 확대 */
    }

    .pwbutton:focus {
        outline: none;             /* 포커스 시 기본 테두리 없애기 */
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 파란색 그림자 효과 */
    }








}
