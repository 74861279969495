.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 9999; /* 모달 오버레이의 z-index 설정 */
}

.modal-overlay.open {
    opacity: 1;
    pointer-events: auto;
}

.modal-content {
    margin: auto;
    border-radius: 8px;
    width: 60%;
    height: 70%;
    position: relative;
    z-index: 10000; /* 모달 콘텐츠의 z-index 설정 */
}

.slide-down {
    animation: slideDown 0.5s ease forwards;
}

.slide-up {
    animation: slideUp 0.5s ease forwards;
}


@keyframes slideDown {
    from {
        transform: translateY(-500px);
        opacity: 1;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-500px);
        opacity: 0; /* 모달이 사라질 때 투명해지도록 설정 */
    }
}



/* 모달 배경 스타일 */
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* 모달 박스 스타일 */
.modal-box {
    width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;

}



/* 에러 메시지 스타일 */
.error-message {
    color: #ff4d4f;
    font-size: 14px;
    margin-bottom: 15px;
}
.submitBtn {
    margin-right:10px ;
    background-color: #007BFF; /* 파란색 배경 */
    color: white;              /* 흰색 글씨 */
    padding: 10px 20px;       /* 안쪽 여백 */
    border: none;             /* 테두리 없음 */
    border-radius: 5px;       /* 둥근 모서리 */
    font-size: 16px;          /* 글씨 크기 */
    cursor: pointer;          /* 포인터 커서 */
    transition: background-color 0.3s; /* 배경색 전환 효과 */
}

.submitBtn:hover {
    background-color: #0056b3; /* 호버 시 색상 변경 */
}

.closeBtn {
    margin-left:10px ;

    background-color: #dc3545; /* 빨간색 배경 */
    color: white;              /* 흰색 글씨 */
    padding: 10px 20px;       /* 안쪽 여백 */
    border: none;              /* 테두리 없음 */
    border-radius: 5px;       /* 둥근 모서리 */
    font-size: 16px;          /* 글씨 크기 */
    cursor: pointer;          /* 포인터 커서 */
    transition: background-color 0.3s; /* 배경색 전환 효과 */
}

.closeBtn:hover {
    background-color: #c82333; /* 호버 시 색상 변경 */
}
@media (max-width: 1000px) {
    .modal-content {
        margin-top: 10px                    ;
        border-radius: 8px;
        width: 90%;
        height: 70%;
        z-index: 10000; /* 모달 콘텐츠의 z-index 설정 */
    }
}
