.forgiveness-container {

    margin-top: 30px;
    width: 700px; /* 최대 너비 설정 */
    min-height: 350px;
    height: auto;
    background-color: whitesmoke;
    padding: 30px;
    border-radius: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* 그림자 효과에 대한 전환 추가 */
    border: 1px solid transparent; /* 기본 테두리는 투명 */
    position: relative; /* 애니메이션 효과를 위한 위치 설정 */
    overflow: hidden; /* 자식 요소가 테두리를 넘지 않도록 설정 */
}

.forgiveness-container:before {
    content: '';
    position: absolute;
    top: -5px; /* 테두리 위치 조정 */
    left: -5px; /* 테두리 위치 조정 */
    right: -5px; /* 테두리 위치 조정 */
    bottom: -5px; /* 테두리 위치 조정 */
    border-radius: 25px; /* 외부 테두리의 둥글기 */
    z-index: -1; /* 컨테이너 뒤로 위치 */
    filter: blur(5px); /* 블러 효과로 부드럽게 */
    opacity: 1; /* 항상 보이도록 설정 */
}

.forgiveness-container {
    box-shadow: 0 0 20px rgba(255, 95, 109, 0.6), /* 반짝이는 효과 */
    0 0 30px rgba(255, 95, 109, 0.4);
}

/* 추가적인 스타일이 필요할 경우 아래에 추가 */
.forgiveness-container:hover {
    transform: scale(1.02); /* 박스 크기 확대 효과 */
}

.forgiveness-container:hover:before {
    opacity: 1; /* 마우스 오버 시 테두리 효과 보이기 */
}

.forgiveness-container:hover {
    box-shadow: 0 0 20px rgba(255, 95, 109, 0.6), /* 반짝이는 효과 */
    0 0 30px rgba(255, 95, 109, 0.4);
}

.incident-date {
    font-family: YiSunShinRegular;
    font-size: 24px; /* 글자 크기 약간 증가 */
    margin-bottom: 10px; /* 여백 조정 */
    font-weight: bold;
    color: black; /* 날짜 강조 색상 */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.incident-text {
    font-family: TTBookendBatangR;
    font-size: 25px;
    margin-bottom: 20px;
    line-height: 1.6; /* 줄 높이 약간 감소 */
    color: #444;
    white-space: pre-wrap;
}

.counts-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
}

.buttons-container {
    margin-top: 40px; /* 버튼 간격 줄임 */
    display: flex;
    justify-content: space-around;
}

.hot-forgive-button,
.hot-not-forgive-button {
    width: 200px;
    color: #fff;
    border: none;
    padding: 14px 28px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* 그림자 효과 추가 */
}

.hot-forgive-button {
    background-color: #4a90e2;
}

.hot-not-forgive-button {
    background-color: #d9534f;
}

.hot-forgive-button:hover,
.hot-not-forgive-button:hover {
    background-color: #ffbb33; /* 호버 시 색상 변화 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 추가 */
}



.forgiveCountBox,
.notforgiveCountBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.forgiveness-count{
    font-size: 40px;
    font-weight: bold;
    color: #4a90e2;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); /* 텍스트에 그림자 */
}



.not-forgiveness-count {
    font-size: 40px;
    font-weight: bold;
    color: #ff6347;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); /* 텍스트에 그림자 */
}


.pop1{
    position: absolute;
    margin-left: 600px;
    width: 500px;
    height: 500px;

}
.pop2{
    position: absolute;
    margin-left: -500px;
    margin-top: 100px;
    width: 300px;
    height: 300px;
}
.pop3{
    position: absolute;
    margin-left: -300px;
    margin-top: 300px;
    width: 500px;
    height: 500px;

}
.theday{
    font-family: YiSunShinRegular;
    font-size: 40px;
    font-weight: bold;
    color: #ff4242; /* 날짜 강조 색상 */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);


}


/* 미디어 쿼리 추가 */
@media (max-width: 1000px) {
    .forgiveness-container {
        width: 85%;
        padding: 20px; /* 모바일에서 패딩 감소 */
    }
    .incident-date {
        color: black;
        font-family: YiSunShinRegular;
        font-size: 20px; /* 글자 크기 조정 */
    }
    .incident-text {
        font-family: TTBookendBatangR;

        font-size: 16px; /* 글자 크기 조정 */
    }
    .counts-container {
        font-size: 20px; /* 글자 크기 조정 */
    }
    .buttons-container {
        margin-top: 20px; /* 여백 조정 */
    }
    .hot-forgive-button,
    .hot-not-forgive-button {
        width: 150px;
        color: #fff;
        border: none;
        padding: 14px 28px;
        border-radius: 10px;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* 그림자 효과 추가 */
    }
    .hot-forgive-button,
    .hot-not-forgive-button {
        width: 130px;
        color: #fff;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* 그림자 효과 추가 */
    }


}
