/* 숫자 롤링 애니메이션 */
@keyframes count-up {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* 숫자 애니메이션 적용 */
.animate {
    animation: count-up 0.5s ease-in-out; /* 더 부드러운 롤링 애니메이션 */
}

/* 숫자 표시 박스 */
.forgiveCount,
.not-forgiveCount {
    color: red;
    background-color: #0088cc;
    font-size: clamp(12px, 2vw, 16px);
    margin-right: 20px;
    min-width: 40px;
    max-width: 80px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* 버튼 스타일 */
.forgive,
.notforgive {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    color: white;
    border: none;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

/* 용서 버튼 색상 */
.forgive {
    background-color: #4a90e2;
}

.forgive:hover {
    background-color: #357ABD;
    transform: scale(1.05);
}

/* 용서하지 않음 버튼 색상 */
.notforgive {
    background-color: #d9534f;
}

.notforgive:hover {
    background-color: #c9302c;
    transform: scale(1.05);
}

/* 레이아웃 스타일 */
.horizontal-border {

    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    width: 900px;
}

.container-wrapper {
    border-bottom: 1px solid #eeeeee;
    width: 90%;
    background-color: #3c4043;
}

.container {
    border-radius: 20px;
    background-color: whitesmoke;
    height: auto;
    position: relative;
    width: 100%;
    padding: 10px;
}

.link-image {
    margin-left: 10px;
    background-image: url(Logo.png); /* 기본 이미지 */
    background-position: 50% 50%;
    background-size: cover;
    border: 1px solid #e4e5e5;
    border-radius: 9999px;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 8px;
    left: 0;
    overflow: hidden; /* ::after 요소가 이미지 경계 밖으로 넘어가지 않도록 */
}

.link-image::after {


    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(Delete.png);
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0; /* 초기 상태에서 투명 */
    transition: opacity 0.5s ease; /* 서서히 나타나는 효과 */
}

.link-image:hover::after {
    opacity: 1; /* 반투명하게 이미지가 서서히 나타남 */
}

.link {
    margin-top: 5px;
    margin-left: 10px;

    position: absolute;
    top: 8px;
    left: 48px;
}

.text-wrapper {
    color: #3c4043;
    font-size: 13.3px;
    font-weight: 700;
    white-space: nowrap;
}

.div {
    margin-left: 10px;
    margin-top: 5px;

    color: #77797b;
    font-size: 10.7px;
    font-weight: 400;
    position: absolute;
    top: 27px;
    left: 48px;
}

.text-wrapper-2 {
    color: #77797b;
    font-size: 10px;
    font-weight: 400;
    position: absolute;
    top: 28px;
    left: 80px;
    text-align: center;
}

.link-2 {
    font-family: ChosunCentennial;



    white-space: pre-wrap;
    margin-top: 50px;
    max-width: 85%;
    margin-left: 50px;
    padding: 10px 0;
    color: #111213;
    font-size: 23px;
    font-weight: 400;
    background-color: whitesmoke;
}

.count-box {
    display: flex;
    flex-direction: row;
    width: 95%;
    background-color: whitesmoke;
    align-items: center;
    padding: 10px;
    justify-content: flex-end;
    gap: 20px;
}
.count {
    width: 80px;
    display: flex;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
}

.delete-text1{
    font-size: 20px;


}
.delete-text2{
    margin-top: 10px;
    font-size: 15px;

}
.delete-input{
    margin-top: 10px;
    width: 60%;
    height: 30px;
    font-size: 20px;
    margin-bottom: 15px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;

}


.modal-box .message {
    margin-top: 15px;
    font-size: 16px;
    color: forestgreen; /* 파란색으로 설정 */
    font-weight: bold;
}

.modal-box .error-message {
    color: #ff4d4f; /* 빨간색으로 설정 */
    font-size: 14px;
    margin-bottom: 15px;
}

.the-line-box{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;

}
.the-line{
    width: 90%;
    height: 1px;
    background-color: grey;


}
.comments-box{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.coment-image{
    margin-left: 50px;
    background-image: url(coments.png); /* 기본 이미지 */
    height: 40px;
    width: 40px;


}
.permission-coments{
    margin-left: 20px;
    font-size: 25px;
    font-family: GumiRomanceTTF;
    color: #000000;

}

@media (max-width: 1000px) {
    .horizontal-border {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 99%;
    }
    .link-2 {
        display: flex;
        font-family: JGaegujaengyi-Medium-KO;
        width: 100%;
        margin-left: 30px;


        white-space: pre-wrap;
        margin-top: 50px;
        color: #111213;
        font-size: 16px;
        font-weight: 400;
    }
    .count-box {
        display: flex;
        flex-direction: row;
        width: 95%;
        background-color: whitesmoke;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
    }
    .notice-button-box{
        display: flex;
        justify-content: space-evenly;


    }
    .modal-box {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
        text-align: center;

    }
    .delete-text1{
        font-size: 20px;

    }
    .delete-text2{
        font-size: 15px;

    }
    .delete-input{

        width: 90%;
        height: 30px;
        font-size: 20px;
        margin-bottom: 15px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        outline: none;
        transition: border-color 0.3s;

    }
    .submitBtn {
        width: 70px;
        height: 40px;

        margin-right:10px ;
        background-color: #007BFF; /* 파란색 배경 */
        color: white;              /* 흰색 글씨 */
        border: none;             /* 테두리 없음 */
        border-radius: 5px;       /* 둥근 모서리 */
        font-size: 1px;          /* 글씨 크기 */
        cursor: pointer;          /* 포인터 커서 */
        transition: background-color 0.3s; /* 배경색 전환 효과 */
    }

    .submitBtn:hover {
        background-color: #0056b3; /* 호버 시 색상 변경 */
    }

    .closeBtn {
        width: 70px;
        margin-left:10px ;
        height: 40px;

        background-color: #dc3545; /* 빨간색 배경 */
        color: white;              /* 흰색 글씨 */
        border: none;              /* 테두리 없음 */
        border-radius: 5px;       /* 둥근 모서리 */
        font-size: 1px;          /* 글씨 크기 */
        cursor: pointer;          /* 포인터 커서 */
        transition: background-color 0.3s; /* 배경색 전환 효과 */
    }

    .closeBtn:hover {
        background-color: #c82333; /* 호버 시 색상 변경 */
    }



    .container {
        height: auto;
        width: 90%;
    }

    .forgive,
    .notforgive {
        width: 100px;
        height: 40px;
        border-radius: 20px;
        color: white;
        border: none;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        transition: background-color 0.3s ease, transform 0.2s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    .coment-image{
        margin-left: 10px;
        background-image: url(coments.png); /* 기본 이미지 */
        height: 40px;
        width: 40px;


    }
    .permission-coments{
        margin-left: 20px;
        font-size: 15px;
        font-family: GumiRomanceTTF;
        color: #000000;

    }





}




